<!-- 
    讲师
    <Curriculum v-for="item in 5" :key="item"></Curriculum>
-->
<template>
  <div class="TeacherCard">
    <div class="">
      <div>
        <img :src="rankImg[teacher.rank]" class="rankImg" alt="" />
      </div>
      <div class="pad-18 flex-alc column">
        <img
          :src="teacher.avatar || require('@/assets/defulatAvatar.png')"
          class="head"
          alt=""
        />
        <div class="name mt-16">{{ teacher.name }}</div>
        <div class="flex-jsb items mt-16 wid_100">
          <div class="flex-alc">
            <div class="fraction">
              {{ Number(teacher.star).toFixed(1) }}
            </div>
            <el-rate
              :value="teacher.star / 2"
              disabled
              text-color="#FF7500"
              :max="5"
            >
            </el-rate>
          </div>
          <div class="all">总课程 {{ teacher.cur_number }}</div>
        </div>
        <el-divider class="mt-15 mb-22"></el-divider>
        <div class="five">
          {{ intercept(teacher.intro) }}
        </div>
        <div class="button click" @click="toDetail">了解讲师</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeacherCard",
  props: {
    teacher: Object,
  },
  data() {
    return {
      rankImg: [
        require("@/assets/one.png"),
        require("@/assets/two.png"),
        require("@/assets/three.png"),
        require("@/assets/three.png"),
        require("@/assets/three.png"),
        require("@/assets/three.png"),
        // require("@/assets/ic_qt@2x.png"),
        // require("@/assets/ic_by@2x.png"),
        // require("@/assets/ic_hj@2x.png"),
        // require("@/assets/ic_bj@2x.png"),
        // require("@/assets/ic_zs@2x.png"),
        // require("@/assets/ic_wp@2x.png"),
      ],
    };
  },

  methods: {
    toDetail() {
      this.$router.push({
        path: "teacherDetail",
        query: { id: this.teacher.id },
      });
    },
    //截取字符串 限制显示数
    intercept(e) {
      if (e.length > 50) {
        if ([",", "，", "。", "、"].indexOf(e[50] != -1)) {
          return e.substring(0, 49) + "...";
        } else {
          return e.substring(0, 50) + "...";
        }
      } else {
        return e;
      }
    },
  },
};
</script>

<style lang='less' scoped>
.TeacherCard {
  background: white;
  border-radius: 15px;
  width: 250px;
  height: 440px;

  .rankImg {
    width: 97px;
    height: 32px;
  }

  .head {
    width: 94px;
    height: 94px;
    border-radius: 100%;
  }
  .name {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
  }

  .fraction {
    font-size: 14px;
    color: #ff7500;
    margin-right: 3px;
  }

  .all {
    font-size: 12px;
    font-weight: 400;
    color: #d3d3d3;
  }

  /deep/.el-rate__icon {
    font-size: 15px;
    margin: 0;
  }

  .el-divider--horizontal {
    height: 2px;
  }

  .five {
    height: 87px;
    white-space: pre-line;
    width: 100%;
  }

  .button {
    width: 199px;
    height: 33px;
    background: linear-gradient(180deg, #ff3764, #ff2604);
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    margin-top: 38px;
  }
}
</style>