<template>
  <div class="Index">
    <el-carousel
      height="508px"
      class="mt-28 carousel"
      :interval="5000"
      autoplay
      loop
    >
      <el-carousel-item
        v-for="(item, index) in bannerList"
        :key="index + item"
        class="swiper"
      >
        <img class="img" :src="item.img" />
      </el-carousel-item>
    </el-carousel>

    <div class="primary">
      <div class="container-left">
        <div class="top">
          <div class="title">
            <img
              src="@/assets/teacher_curriculum.png"
              class="hotCurriculum"
              alt=""
            />
            <div class="all click" @click="goCurriculum">
              全部 <i class="el-icon-arrow-right"></i>
            </div>
          </div>

          <div class="flex wid_100 wrap mt-33">
            <Curriculum
              v-for="(item, index) in hotCurriculum"
              :curriculum="item"
              :key="'cur' + index"
              class="mb-20"
              :class="{ 'mr-20': (index + 1) % 4 != 0 }"
            ></Curriculum>
          </div>
        </div>

        <div class="bottom mt-47">
          <div class="title">
            <img src="@/assets/teacher.png" class="teacher" alt="" />
            <div class="all click" @click="teaMuch">
              更多 <i class="el-icon-arrow-right"></i>
            </div>
          </div>

          <div class="flex-alc wrap mt-33">
            <TeacherCard
              v-for="(item, index) in teacherList"
              :key="'tea' + index"
              :teacher="item"
              class="mb-20"
              :class="{ 'mr-20': (index + 1) % 3 != 0 }"
            ></TeacherCard>
          </div>
        </div>
      </div>

      <div class="container-right">
        <div class="top" v-if="target.length">
          <img src="@/assets/image_wdmb@3x.png" class="wdmbImg" alt="" />

          <div
            class="target click"
            @click="toMaster(item.id)"
            v-for="(item, index) in target"
            :key="'target' + index"
          >
            <div class="flex-als between">
              <div class="title">{{ item.name }}</div>
              <div class="speed">
                已完成 {{ item.progress }}%
                <img src="@/assets/ic_ygxxsc@2x.png" class="yjtImg" />
              </div>
            </div>
            <el-divider class="mt-17 mb-11 h-2 back-f6"></el-divider>
            <div class="flex-alc between">
              <div class="flex-alc plan">
                <div class="type">线上</div>
                <div class="tecPlan">学习计划</div>
              </div>
              <div class="time">{{ item.lecturer_str }}</div>
            </div>
          </div>
        </div>

        <div
          class="bottom"
          :style="{ 'margin-top': target.length ? '100px' : '0px' }"
        >
          <div class="wh-tip" :style="{ display: showTipWh ? 'flex' : 'none' }">
            {{
              showTipWh == 2
                ? "完成课程学习后获得源源不断的积分哦~"
                : "您需要完成个人导师给您布置的专项任务方可获得成长值喔~"
            }}
          </div>
          <div class="flex-alc between">
            <div
              class="flex-alc column click"
              @click="selRanking(0)"
              @mouseover="showTipWh = 1"
              @mouseout="showTipWh = 0"
            >
              <div
                class="rankingText"
                :style="{
                  color: ranking == 0 ? '#E41414' : '#000',
                }"
              >
                成长值排行榜
              </div>
              <div class="ranking" :class="{ opt0: ranking != 0 }"></div>
            </div>
            <div
              class="flex-alc column click"
              @click="selRanking(1)"
              @mouseover="showTipWh = 2"
              @mouseout="showTipWh = 0"
            >
              <div
                class="rankingText"
                :style="{
                  color: ranking == 1 ? '#E41414' : '#000',
                }"
              >
                积分值排行榜
              </div>
              <div class="ranking" :class="{ opt0: ranking != 1 }"></div>
            </div>
          </div>

          <div class="flex-alc column mt-20">
            <div class="re" v-show="ranking == 0">
              <img src="@/assets/image_czz@2x.png" class="ball" alt="" />
              <div class="growth">
                <div style="line-height: 1">
                  {{ my_growth }}
                </div>
                <div class="growth-text">我的成长值</div>
              </div>
            </div>
            <div class="flex-alc column" v-show="ranking == 1">
              <div class="number">{{ total_credit }}</div>
              <div class="my-rate">我的积分</div>
            </div>
            <div class="month mt-10 mb-15">
              <div>
                {{
                  ranking
                    ? "以下是积分排行榜单（实时更新）"
                    : `以下是${month}月榜单`
                }}
              </div>
              <!-- <img
                                @mouseover="showTipWh = true"
                                @mouseout="showTipWh = false"
                                src="@/assets/ic_wh@2x.png"
                                class="wh-img click"
                            /> -->
            </div>
          </div>

          <div
            class="flex-alc between pl-35 pr-35 mt-13 mb-36"
            v-show="ranking == 0"
          >
            <div class="flex-alc column click" @click="selType(0)">
              <div class="list">月榜</div>
              <div class="selList" :class="{ opt0: typeRanking != 0 }"></div>
            </div>
            <div class="flex-alc column click" @click="selType(1)">
              <div class="list">总榜</div>
              <div class="selList" :class="{ opt0: typeRanking != 1 }"></div>
            </div>
          </div>

          <div class="pl-13 pr-13">
            <div
              v-if="!rankingList.length"
              class="mt-52 flex-alc column mb-120"
            >
              <div class="no-data mb-26">暂时还没有排名呢</div>
              <div class="no-data-line"></div>
            </div>
            <div
              class="flex-alc between mb-23"
              v-for="(item, index) in rankingList"
              :key="'user' + index"
              v-show="index < 3"
            >
              <div class="flex-alc">
                <img :src="ImgList[index]" class="rankingImg mr-15" />
                <div class="text">{{ item.name }}</div>
              </div>
              <div class="text">
                {{ ranking ? item.credit : item.amount }}
              </div>
            </div>
          </div>

          <div
            class="lookRanking click"
            v-if="rankingList.length"
            @click="lookRanking"
          >
            查看全榜 >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Curriculum from "@/components/Curriculum.vue";
import TeacherCard from "@/components/TeacherCard.vue";
export default {
  name: "Index",
  components: {
    Curriculum,
    TeacherCard,
  },
  computed: {
    user: function() {
      return this.$store.state.user;
    },
    isLogin: function() {
      return this.$store.state.isLogin;
    },
  },
  watch: {
    isLogin: function() {
      this.getRanking();
      this.getStudy();
    },
  },
  data() {
    return {
      showTipWh: false, //显示成长值和积分获取说明
      ImgList: [
        require("@/assets/jp.png"),
        require("@/assets/yp.png"),
        require("@/assets/tp.png"),
      ], //金银铜排行图
      bannerList: [], //轮播图
      ranking: 0, //0 成长值排行 1 积分排行
      typeRanking: 0, //0 月榜 1 总榜
      hotCurriculum: [], //热门课程
      teacherList: [], //讲师
      rankingList: [], //排行列表
      my_growth: 0, //我的成长值
      month: 0, //月份
      target: [], //我的学习任务
      total_credit: 0, //我的积分
    };
  },

  async mounted() {
    window.localStorage.setItem("navActive", 0);
    this.$store.commit("switchNav", 0);
    //获取轮播图
    this.$https.get("/api/banner/list").then((res) => {
      this.bannerList = res.data.list || [];
    });

    //获取热门课程
    this.$https.get("/api/cur/list", { page: 1, limit: 8 }).then((res) => {
      this.hotCurriculum = res.data.list;
    });

    //获取我的学习任务
    if (this.isLogin) {
      this.getRanking();
      this.getStudy();
    }

    //获取讲师列表
    this.$https
      .get("/api/lecturer/list", { page: 1, limit: 6 })
      .then((res) => {
        this.teacherList = res.data.list;
      });

    //成长值排行榜
  },

  methods: {
    //获取学习计划
    getStudy() {
      this.$https
        .get("/api/learn/plan/list", { status: 1, page: 1, limit: 3 })
        .then((res) => {
          if (res.errcode) return;
          this.target = res.data.list;
        });
    },
    //个人中心-我的师傅  学习计划跳转课程详情
    toMaster(e) {
      this.$router.push({
        path: "/CurriculumDetail",
        query: { id: e },
      });
      // window.localStorage.setItem("navActive", -1);
      // this.$store.commit("switchNav", -1);
      // window.localStorage.setItem("myNavActive", 2);
      // this.$store.commit("switchMyNav", 2);
      // this.$router.push({ path: "/myInfo/master" });
    },
    //查看全榜
    lookRanking() {
      if (this.ranking == 0) {
        window.localStorage.setItem("navActive", 4);
        this.$store.commit("switchNav", 4);
        window.localStorage.setItem("myNavActive", -2);
        this.$store.commit("switchMyNav", -2);
        this.$router.push({ path: "/myInfo/growth" });
      } else {
        window.localStorage.setItem("navActive", 4);
        this.$store.commit("switchNav", 4);
        window.localStorage.setItem("myNavActive", 0);
        this.$store.commit("switchMyNav", 0);
        this.$router.push({ path: "/myInfo/integral" });
      }
    },
    //切换排行榜
    selRanking(e) {
      this.ranking = e;
      this.getRanking();
    },
    //切换月/总榜
    selType(e) {
      this.typeRanking = e;
      this.getRanking();
    },
    //全部（课程）
    goCurriculum() {
      window.localStorage.setItem("navActive", 1);
      this.$store.commit("switchNav", 1);
      this.$router.push({ path: "CurriculumTypeList" });
    },
    //更多(讲师库)
    teaMuch() {
      this.$router.push({ path: "teacherList" });
    },
    //获取排行榜
    getRanking() {
      if (this.ranking == 0) {
        this.$https
          .get("/api/my_master/growth_rank", {
            kind: this.typeRanking ? "total" : "month",
            id: this.user.id || 0,
          })
          .then((res) => {
            this.rankingList = res.data.list || [];
            this.my_growth = res.data.my_growth;
            this.month = res.data.month;
          });
      } else {
        this.$https
          .get("/api/credit_log/ranking", {
            page: 1,
            limit: 3,
          })
          .then((res) => {
            this.rankingList = res.data.list || [];
            this.total_credit = res.data.total_credit;
          });
      }
    },
  },
};
</script>

<style scoped lang="less">
.Index {
  width: 1200px;
  margin: auto;
  .carousel {
    /deep/.img {
      width: 100%;
      height: 100%;
    }

    /deep/.el-carousel__button {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: #9b9b9b;
    }

    /deep/.el-carousel__indicator.is-active button {
      background: #e41414;
    }
  }

  .primary {
    margin-top: 43px;
    display: flex;
    padding-bottom: 90px;

    .container-left {
      margin-right: 40px;
      width: 100%;

      .top {
        .title {
          font-size: 19px;
          font-weight: 400;
          color: #9b9b9b;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .hotCurriculum {
            width: 147px;
            height: 29px;
          }

          .all {
            display: flex;
            align-items: center;
          }
        }
      }

      .bottom {
        .title {
          font-size: 19px;
          font-weight: 400;
          color: #9b9b9b;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .teacher {
            width: 140px;
            height: 28px;
          }

          .all {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .container-right {
      .top {
        .wdmbImg {
          width: 198px;
          height: 29px;
          margin-bottom: 32px;
        }
        .target {
          padding: 13px 17px;
          width: 366px;
          background: white;
          border-radius: 15px;
          margin-bottom: 15px;

          .title {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            width: 225px;
          }
          .speed {
            font-size: 12px;
            font-weight: 400;
            color: #000000;
            display: flex;
            align-items: center;

            .yjtImg {
              width: 18px;
              height: 11px;
              margin-left: 12px;
            }
          }
          .plan {
            border-radius: 5px;

            .type {
              background: #a0cc61;
              font-size: 10px;
              font-weight: 400;
              color: #ffffff;
              padding: 1px 4px;
              border-radius: 5px 0 0 5px;
            }

            .tecPlan {
              font-size: 10px;
              font-weight: 400;
              color: #a0cc61;
              padding: 1px 4px;
              background: white;
            }
          }

          .time {
            font-size: 12px;
            font-weight: 400;
            color: #e41414;
          }
        }
      }

      .bottom {
        width: 368px;
        margin-top: 100px;
        padding: 28px 34px 14px 34px;
        background: #ffffff;
        border-radius: 4px 4px 15px 15px;
        position: relative;

        .wh-tip {
          position: absolute;
          top: -80px;
          padding: 0 10px;
          left: 47px;
          width: 273px;
          height: 69px;
          background: #ffffff;
          box-shadow: 0px 2px 9px 0px #c2c2c2;
          border-radius: 5px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #fe3247;
          display: none;
          align-items: center;
          justify-content: center;
          z-index: 99;
        }

        .rankingText {
          font-size: 22px;
          font-weight: 400;
          color: #000000;
          margin-bottom: 8px;
        }
        .ranking {
          width: 43px;
          height: 4px;
          background: linear-gradient(180deg, #ff3764, #ff2604);
          border-radius: 4px;
        }

        .ball {
          width: 123px;
          height: 123px;
        }
        .number {
          font-size: 40px;
          font-weight: 500;
          color: #e41414;
        }
        .my-rate {
          font-size: 14px;
          font-weight: 500;
          color: #000000;
        }
        .month {
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          display: flex;
          align-items: center;
          position: relative;
          .wh-img {
            width: 20px;
            height: 20px;
            margin-left: 2px;
          }
        }
        .growth {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          font-size: 40px;
          font-weight: 500;
          color: #ffffff;
          .growth-text {
            font-size: 12px;
          }
        }

        .list {
          font-size: 18px;
          font-weight: 500;
          color: #000000;
        }

        .selList {
          width: 44px;
          height: 3px;
          background: linear-gradient(180deg, #ff3764, #ff2604);
          border-radius: 4px;
          margin-top: 5px;
        }

        .text {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
        }
        .no-data {
          font-size: 18px;
          font-weight: 400;
          text-align: center;
          color: #7f7f7f;
        }
        .no-data-line {
          width: 249px;
          height: 1px;
          opacity: 0.17;
          border: 1px solid #979797;
        }
        .rankingImg {
          width: 23px;
          height: 29px;
        }
        .lookRanking {
          font-size: 14px;
          font-weight: 400;
          color: #929292;
          text-align: center;
        }
      }
    }
  }
}
</style>
