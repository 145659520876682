var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Curriculum click",class:{ 'border-ra': _vm.proess || _vm.showBorder },style:({ width: _vm.imgSize + 'px' }),on:{"click":_vm.details}},[_c('div',{staticClass:"re flex"},[_c('el-image',{staticClass:"img",style:({
                width: _vm.imgSize + 'px',
                'border-radius':
                    _vm.border + 'px ' + _vm.border + 'px ' + '0px ' + '0px',
            }),attrs:{"src":_vm.isOff
                    ? _vm.curriculum.offline
                        ? _vm.curriculum.offline.cover
                        : ''
                    : _vm.curriculum.cover,"alt":""}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"placeholder"},slot:"placeholder"},[_vm._v(" 加载中"),_c('span',{staticClass:"dot"},[_vm._v("...")])])]),(_vm.proess)?_c('div',{staticClass:"proess"},[_c('div',{staticClass:"rate"},[_vm._v(_vm._s(_vm.progress)+"%")]),(!_vm.study)?_c('div',{staticClass:"rate click",on:{"click":function($event){$event.stopPropagation();return _vm.cancel($event)}}},[_vm._v(" 取消收藏 ")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"details",style:({
            'border-radius':
                '0px ' + '0px ' + _vm.border + 'px ' + _vm.border + 'px ',
            height: _vm.isOff ? '105px' : '85px',
        })},[_c('div',{staticClass:"cur-title one-line two"},[_vm._v(" "+_vm._s(_vm.isOff ? _vm.curriculum.offline ? _vm.curriculum.offline.title : "" : _vm.curriculum.name)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOff),expression:"isOff"}],staticClass:"desc one-line"},[_vm._v(" "+_vm._s(_vm.curriculum.offline ? _vm.curriculum.offline.desc : "")+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOff),expression:"isOff"}],staticClass:"status"},[_vm._v(" "+_vm._s(_vm.curriculum.status == 1 ? "已完成" : "未完成")+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isOff),expression:"!isOff"}],staticClass:"info"},[_c('div',{staticClass:"name one-line"},[_vm._v(" "+_vm._s(_vm.curriculum.lecturer_str)+" ")]),_c('div',{staticClass:"flex-alc"},[_c('div',{staticClass:"fabulous mr-13"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/dz.png"),"alt":""}}),_c('div',{staticClass:"ml-5"},[_vm._v(" "+_vm._s(_vm.curriculum.like_number)+" ")])]),_c('div',{staticClass:"fabulous"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/pl.png"),"alt":""}}),_c('div',{staticClass:"ml-5"},[_vm._v(" "+_vm._s(_vm.curriculum.estimate_number)+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }