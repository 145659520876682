<!-- 
    imgSize 图片宽度 curriculum 课程
    <Curriculum :imgSize='218' v-for="item in 5" :curriculum='item'  :key="item"></Curriculum>
    
-->
<template>
    <div
        class="Curriculum click"
        :class="{ 'border-ra': proess || showBorder }"
        @click="details"
        :style="{ width: imgSize + 'px' }"
    >
        <div class="re flex">
            <el-image
                :src="
                    isOff
                        ? curriculum.offline
                            ? curriculum.offline.cover
                            : ''
                        : curriculum.cover
                "
                class="img"
                :style="{
                    width: imgSize + 'px',
                    'border-radius':
                        border + 'px ' + border + 'px ' + '0px ' + '0px',
                }"
                alt=""
            >
                <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                </div>
            </el-image>
            <div class="proess" v-if="proess">
                <div class="rate">{{ progress }}%</div>
                <div class="rate click" @click.stop="cancel" v-if="!study">
                    取消收藏
                </div>
            </div>
        </div>
        <div
            class="details"
            :style="{
                'border-radius':
                    '0px ' + '0px ' + border + 'px ' + border + 'px ',
                height: isOff ? '105px' : '85px',
            }"
        >
            <div class="cur-title one-line two">
                {{
                    isOff
                        ? curriculum.offline
                            ? curriculum.offline.title
                            : ""
                        : curriculum.name
                }}
            </div>
            <div v-show="isOff" class="desc one-line">
                {{ curriculum.offline ? curriculum.offline.desc : "" }}
            </div>
            <div v-show="isOff" class="status">
                {{ curriculum.status == 1 ? "已完成" : "未完成" }}
            </div>
            <div class="info" v-show="!isOff">
                <div class="name one-line">
                    {{ curriculum.lecturer_str }}
                </div>
                <div class="flex-alc">
                    <div class="fabulous mr-13">
                        <img src="@/assets/dz.png" alt="" class="icon" />
                        <div class="ml-5">
                            {{ curriculum.like_number }}
                        </div>
                    </div>
                    <div class="fabulous">
                        <img src="@/assets/pl.png" alt="" class="icon" />
                        <div class="ml-5">
                            {{ curriculum.estimate_number }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Curriculum",
        props: {
            //是否显示边框
            showBorder: {
                type: Boolean,
                default: false,
            },
            //是否为学习计划
            study: {
                type: Boolean,
                default: false,
            },
            //进度
            progress: {
                type: Number,
                default: 0,
            },
            //图片大小
            imgSize: {
                type: Number,
                default: 183,
            },
            //边角大小
            border: {
                type: Number,
                default: 15,
            },
            //课程对象
            curriculum: {
                type: Object,
                default: () => {
                    return {
                        offline: { desc: "", cover: "", title: "" },
                    };
                },
            },
            //禁止点击进入详情
            noClick: {
                type: Boolean,
                default: false,
            },
            //是否显示进度和取消收藏
            proess: {
                type: Boolean,
                default: false,
            },
            //是否是线下课程
            isOff: {
                type: Number,
                default: 0,
            },
        },

        methods: {
            details() {
                if (!this.noClick) {
                    if (this.isOff) {
                        this.$router.push({
                            path: "/OffLineCurriculumDetail",
                            query: { id: this.curriculum.id },
                        });
                    } else {
                        this.$router.push({
                            path: "/CurriculumDetail",
                            query: { id: this.curriculum.id },
                        });
                    }
                } else {
                    this.$emit("selCur", this.curriculum);
                }
            },
            //取消收藏
            cancel() {
                this.$emit("cancel", { id: this.curriculum.id });
            },
        },
    };
</script>

<style lang='less' scoped>
.Curriculum {
    display: flex;
    flex-direction: column;

    .img {
        height: 119px;
        /deep/.image-slot {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            background: #f5f7fa;
            font-size: 14px;
            color: #c0c4cc;
        }
    }
    .proess {
        position: absolute;
        bottom: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 7px;

        .rate {
            padding: 2px 12px;
            font-size: 12px;
            font-weight: 400;
            color: #f32929;
            opacity: 0.86;
            background: #fcdddd;
            border-radius: 10px;
        }
    }

    .details {
        background: #ffffff;
        padding: 8px;
        height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .cur-title {
            font-size: 14px;
            font-weight: 500;
            color: #000000;
            -webkit-line-clamp: 2;
        }
        .desc {
            font-size: 12px;
            font-weight: 400;
            color: #797979;
            margin-right: 15px;
            -webkit-line-clamp: 1;
        }
        .status {
            width: 57px;
            height: 19px;
            background: #fcdddd;
            border-radius: 10px;
            font-size: 12px;
            font-weight: 400;
            color: #f32929;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .already {
            color: #21ad29;
            background: #e6ffe7;
        }
        .info {
            display: flex;
            align-items: center;
            margin-top: 10px;
            justify-content: space-between;

            .name {
                font-size: 12px;
                font-weight: 400;
                color: #797979;
                margin-right: 15px;
            }

            .fabulous {
                font-size: 11px;
                font-weight: 400;
                color: #797979;
                display: flex;
                align-items: center;

                .icon {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}
.border-ra {
    border: 1px solid #e0e0e0;
    border-radius: 6px;
}
</style>